<template>
  <div class="bankAccountDetails page-info-content">
    <el-tabs v-model="activeName">
      <el-tab-pane name="one" label="开户信息">
        <el-descriptions class="margin-top" title :column="3">
          <el-descriptions-item label="会员编码">
            {{ meetingInformationDetails.cmpId }}
          </el-descriptions-item>
          <el-descriptions-item label="会员名称">
            {{ meetingInformationDetails.cmpName }}
          </el-descriptions-item>
          <el-descriptions-item label="结算银行">
            {{ meetingInformationDetails.settleBankName }}
          </el-descriptions-item>
          <el-descriptions-item label="银行席位号">
            {{ meetingInformationDetails.bankCmpId }}
          </el-descriptions-item>
          <el-descriptions-item label="开户账号">
            {{ meetingInformationDetails.openAccNo }}
          </el-descriptions-item>
          <el-descriptions-item label="开户行">
            {{ meetingInformationDetails.openBankName }}
          </el-descriptions-item>
          <el-descriptions-item label="开户行网点">
            {{ meetingInformationDetails.openBankNet }}
          </el-descriptions-item>
          <el-descriptions-item label="状态">
            <span v-if="meetingInformationDetails.state === '01'">审核通过</span>
            <span v-else-if="meetingInformationDetails.state === '02'">待平台审核</span>
            <span v-else-if="meetingInformationDetails.state === '10'">待银行审核</span>
            <span v-else-if="meetingInformationDetails.state === '11'">银行拒绝</span>
            <span v-else-if="meetingInformationDetails.state === '-1'">平台拒绝</span>
          </el-descriptions-item>
          <el-descriptions-item :span="3" label="备注">
            {{ meetingInformationDetails.remark }}
          </el-descriptions-item>
        </el-descriptions>
      </el-tab-pane>
      <el-tab-pane name="two" label="提现账户">
        <Table :item-data="itemData" :list-data="listData" />
        <el-button size="small" type="primary" class="widen-button" @click="addCashAccount">
          新增绑定
        </el-button>
      </el-tab-pane>
    </el-tabs>
    <!-- 弹出的新增跟修改框 -->
    <Dialog
      ref="dialog"
      :edit-form-data="addFormData"
      :add-and-edit-form-item="addAndEditFormItem"
      @getFormData="getFormData"
    />
  </div>
</template>

<script>
import Table from '@/components/Table'
import Dialog from '@/components/Dialog'
import { cmpBankinfoDetail, cashAccountDetail, cashAccountAdd } from '@/api/enterpriseInformation'
export default {
  components: { Table, Dialog },
  data() {
    return {
      activeName: 'one',
      itemData: [
        { label: '开户行名称', prop: 'openBankName' },
        {
          label: '开户行账号', prop: 'openAccNo'
        },
        { label: '开户行网点', prop: 'openBankNet' }
      ],
      meetingInformationDetails: {},
      addAndEditFormItem: [
        { type: 'input', label: '开户行名称', value: 'openBankName' },
        {
          type: 'input', label: '开户行账号', inputType: 'number', value: 'openAccNo', rules: [
            { required: true, message: '请输入开户行账号', trigger: 'blur' },
            { min: 6, max: 20, message: '长度在 6 到 20 个字符', trigger: 'blur' }
          ]
        },
        { type: 'input', label: '开户行网点', value: 'openBankNet' }
      ],
      listData: [],
      addFormData: {}
    }
  },
  mounted() {
    if (this.$route.query.id) {
      cmpBankinfoDetail({ id: this.$route.query.id }, res => {
        this.meetingInformationDetails = { ...res.data }
      })
    }
    if (this.$route.query.withParameters) {
      this.activeName = 'two'
    }
    if (this.$route.query.cmpId && this.$route.query.settleBankId) {
      this.getCashAccountDetail()
    }
  },
  methods: {
    // 获取提现账户列表
    getCashAccountDetail() {
      cashAccountDetail({ cmpId: this.$route.query.cmpId, settleBankId: this.$route.query.settleBankId }, res => {
        this.listData = [...res.data]
      })
    },
    // 点击新增绑定按钮
    addCashAccount() {
      if (this.meetingInformationDetails.state !== '01') {
        this.$message('您的账户还未审核通过，暂不支持添加提现账户！')
        return
      }
      if (this.listData.length >= 10) {
        this.$message('您的提现账户已有十条，暂不支持添加')
        return
      }
      this.$refs.dialog.editFormVisible = true
      this.addFormData = {}
    },
    getFormData(value) {
      value.id = this.$route.query.id
      cashAccountAdd(value, () => {
        this.$message.success('添加成功！')
        this.$refs.dialog.editFormVisible = false
        this.getCashAccountDetail()
      })
    }
  }
}
</script>

<style lang="scss">
.bankAccountDetails {
  width: 100%;
  .el-descriptions {
    padding: 32px;
    .el-descriptions-row td {
      padding-bottom: 32px;
    }
  }
  .el-tabs {
    text-align: center;
    .el-button {
      margin-top: 40px;
    }
  }
}
</style>
